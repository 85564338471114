/* IntroductionのCSS */

.introduction {
    position: relative;
    margin: 32px auto 0 auto;
    max-width: 1024px;
}

.introductionTitleContainer {
    background: linear-gradient(to top, rgba(244, 114, 182, 0.6), rgba(249, 168, 212, 0.6));
    width: 96%;
    margin: 0 auto;
    border-radius: 16px;
    box-shadow: 0 0 20px gray;
}

@media (max-width: 680px) {
    .introductionTitleContainer {
        display: none;
    }
}

.introductionDecoration {
    display: block;
    max-width: 768px;
    width: 90%;
    max-height: 32px;
    height: 90%;
    aspect-ratio: 24 / 1;
    margin: 0 auto;
}

.introductionTitle {
    position: relative;
    display: block;
    text-align: center;
    font-size: 28px;
    margin: 4px;
    text-shadow: -1px -1px white, 1px 1px white, 1px 1px white, -1px -1px white, /* 太い影 */
    -2px -2px white, 2px 2px white, -2px 2px white, 2px -2px white;
    /* 更に太くするための追加の影 */
}


/* 改行調整 */

@media (max-width: 1024px) {
    .introductionTitle {
        white-space: pre-line;
    }
}


/* 改行調整（フォントサイズで） */

@media (max-width: 600px) {
    .introductionTitle {
        font-size: calc(100vw / 22);
        text-shadow: -1px -1px white, 1px 1px white, 1px 1px white, -1px -1px white;
        /* 更に太くするための追加の影 */
    }
}

.introductionMusic {
    position: relative;
    width: 300px;
    height: 300px;
    box-shadow: 0 0 20px gray;
    border-radius: 20px;
    margin: 16px auto;
    background-color: rgba(255, 255, 255, 0.8);
}

.introductionMusicText {
    position: relative;
    display: block;
    text-align: center;
    font-size: 20px;
    margin: 0;
    padding-top: 8px;
    text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
}

.introductionMusicButton {
    display: block;
    margin: 16px auto;
    width: 100px;
    height: 100px;
    transition: all 0.3s ease;
}

.introductionMusicButton:hover {
    opacity: 0.7;
    transform: scale(1.1);
}

.precaution {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.precautionContainer {
    max-width: 300px;
    width: 80%;
    max-height: 200px;
    height: 80%;
    background-color: white;
    padding: 16px;
    margin: calc(50vh - 150px) auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
}

.precautionText {
    font-size: 24px;
    text-align: center;
}

.precautionButton {
    display: block;
    width: 100px;
    height: 40px;
    margin: 0 auto;
    font-size: 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.precautionButtonContainer {
    display: flex;
    justify-content: space-between;
}

.precautionButton.back {
    background-color: darkgray;
}

.precautionButton:hover {
    background-color: #3D8B3D;
}

.precautionButton.back:hover {
    background-color: gray;
}

.introductionItem {
    position: relative;
    width: 300px;
    height: 300px;
    box-shadow: 0 0 20px gray;
    border-radius: 20px;
    margin: 16px;
}

.introductionComponent {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
    margin: 16px auto;
}

.introductionItemImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.3s ease;
    border-radius: 20px;
}

.introductionItemDecoration {
    position: absolute;
    top: -64px;
    left: 0;
    width: 100%;
    transition: all 0.3s ease;
    border-radius: 20px;
    transform: scale(1.2);
    pointer-events: none;
    opacity: 0.5
}

.introductionItem:hover .introductionItemImage {
    transform: scale(1.02);
}

.introductionItem:hover .itemTitle,
.introductionItem:hover .itemText {
    transform: scale(1.1);
}

.introductionItem:hover .introductionItemContentImage {
    transform: translate(-50%, -50%) scale(1.1);
}

.itemTitle {
    position: absolute;
    top: -16px;
    left: 0;
    width: 100%;
    height: 50px;
    color: black;
    text-shadow: -2px 0 white, 0 2px white, 2px 0 white, 0 -2px white;
    font-size: 36px;
    text-align: center;
    line-height: 50px;
    transition: all 0.3s ease;
}

@media (max-width: 512px) {
    .itemTitle {
        text-shadow: -1px 0 white, 0 2px white, 2px 0 white, 0 -1px white;
    }
}

.introductionItemContentImage {
    position: absolute;
    width: 150px;
    height: 150px;
    top: 52%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.3s ease;
    border-radius: 20px;
    opacity: 0.9;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.itemText {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    color: black;
    text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
    font-size: 20px;
    text-align: center;
    line-height: 50px;
    transition: all 0.3s ease;
}