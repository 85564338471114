.newsList {
    position: relative;
    margin: 0 auto;
    max-width: 936px;
    width: 100%;
}

.newsItem {
    position: relative;
    border-bottom: 1px dashed gray;
    width: 100%;
    height: 104px;
    padding: 4px 0;
}

.newsItemDate {
    position: relative;
    width: 60%;
    overflow: hidden;
    margin: 4px;
}

.newsItemTitle {
    display: block;
    position: relative;
    width: 60%;
    font-size: 24px;
    margin: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.newsItemContent {
    position: relative;
    width: 65%;
    margin: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.newsItemImage {
    position: absolute;
    top: 6px;
    right: 20%;
    width: 100px;
    height: 100px;
}

.newsItemImageCenter {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.newsItemImageCenter img {
    position: relative;
    max-width: 100px;
    max-height: 100px;
}

.readMoreButton {
    position: absolute;
    bottom: 4px;
    right: 4px;
    font-size: 20px;
    color: black;
    text-decoration: none;
    transition: all 0.3s ease;
}

.readMoreButton:hover {
    color: gray;
    transform: translateY(3px);
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.paginationButton {
    margin: 0 5px;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    background-color: #f0f0f0;
    cursor: pointer;
    text-decoration: none;
    color: black;
}

.paginationButton.active {
    background-color: #007bff;
    color: white;
}

@media (max-width: 1024px) {
    .newsItemContent {
        width: calc(100% - 116px);
    }
    .newsItemImage {
        right: 2%;
    }
    .readMoreButton {
        display: none;
    }
}

@media (max-width: 500px) {
    .newsItem {
        height: calc(100vw / 5 + 4px);
    }
    .newsItemTitle {
        font-size: calc(100vw / 24);
    }
    .newsItemDate,
    .newsItemContent {
        font-size: calc(100vw / 32);
    }
    .newsItemImage {
        width: calc(100vw / 5);
        height: calc(100vw / 5);
    }
    .newsItemImageCenter img {
        max-width: calc(100vw / 5);
        max-height: calc(100vw / 5);
    }
}

