/* AccessのCSS */

.access {
    position: relative;
    max-width: 936px;
    padding: 32px;
    margin: 0 auto 64px auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.8);
}

.accessTitle {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    text-shadow: -2px 0 white, 0 2px white, 2px 0 white, 0 -2px white;
    z-index: 1;
}

.accessTitleBackground {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 220px;
    height: 64px;
    margin-left: -110px;
    /* widthの半分 */
    margin-top: -32px;
    /* heightの半分 */
    z-index: -1;
}

.accessTitleLeft {
    width: 48px;
    height: 48px;
}

.school-info {
    width: 100%;
    max-width: 860px;
    margin: 32px auto;
}

.school-info table {
    width: 100%;
    border-collapse: collapse;
}

.school-info th,
.school-info td {
    padding: 10px;
    border: 1px solid #ddd;
}

.school-info th {
    background-color: rgba(255, 255, 255, 0.5);
    text-align: center;
    font-size: 20px;
}

.school-info td {
    text-align: center;
    font-size: 20px;
}

.classroomImageTitle {
    font-size: 24px;
    text-align: center;
    margin-top: 32px;
}

.classroomImage {
    display: block;
    max-width: 368px;
    width: 100%;
    aspect-ratio: 1 / 1;
    margin: 0 auto;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

@media (max-width: 1024px) {
    .access {
        width: 84%;
        padding: calc(84% / 30);
    }
    .school-info th,
    .school-info td {
        font-size: 16px;
    }
}