/* ContactのCSS */
.contact {
    max-width: 768px;
    margin: 0 auto 64px auto;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.8);
}

.contactTitle {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    text-shadow: -2px 0 white, 0 2px white, 2px 0 white, 0 -2px white;
    z-index: 1;
}

.contactTitleBackground {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 280px;
    height: 64px;
    margin-left: -140px;
    /* widthの半分 */
    margin-top: -32px;
    /* heightの半分 */
    z-index: -1;
}

.contactTitleLeft {
    width: 48px;
    height: 48px;
}

.contactText {
    position: relative;
    display: block;
    font-size: 20px;
    text-align: center;
}

.contactForm {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    /* 子要素をストレッチして幅全体に広げます */
    height: 1080px;
    transition: height 0.5s ease-in-out;
}

.contactFormAdjust {
    height: 844px;
    transition: height 0.5s ease-in-out;
}

.contactTrialText {
    font-size: 20px;
    margin: 0;
    opacity: 0;
    color: gray;
}

.contactTrialTextVisible {
    opacity: 1;
    transition: opacity 1.0s ease-in-out;
}

.contactRow {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    /* 子要素をストレッチして幅全体に広げます */
    opacity: 0;
}

.contactLabel {
    position: relative;
    margin-bottom: 25px;
    /* ラベルの下の余白 */
    display: block;
    /* ラベルをブロック要素として表示 */
    font-size: 24px;
}

.contactVisible {
    opacity: 1;
    transition: opacity 1.0s ease-in-out;
}

@media (max-width: 512px) {
    .contactEmail {
        margin-bottom: 50px;
    }
}

.contactTextareaMoving {
    transform: translateY(0);
    transition: transform 0.5s ease-in-out;
}

.contactTextareaStatic {
    transform: translateY(-236px);
    /* 初期位置を下に設定 */
    transition: transform 0.5s ease-in-out;
}

.contactLabelRow {
    width: 120px;
}

.contactInput,
.contactSelect,
.contactTextarea {
    width: 100%;
    /* 幅を100%に設定して親要素に合わせます */
    line-height: 1.2;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-sizing: border-box;
    font-size: 20px;
    /* パディングとボーダーを幅に含める */
    height: 42px;
    background-color: rgba(255, 255, 255, 0);
    /* #f5f5f5を半透明に設定 */
}
.contactSelect {
    padding: 4px;
}

.contactSelect option {
    color: black;
}

.contactSelect.placeholder {
    color: grey;
}

.contactTextarea {
    height: 256px;
    /* テキストエリアの高さを指定 */
    resize: vertical;
    /* 垂直方向のリサイズのみを許可 */
}

/* 必須の項目には赤色の文字を表示 */
.contactRequired {
    color: red;
    font-size: 12px;
    /* フォントサイズを小さくしています */
    vertical-align: super;
    /* 上付き文字にします */
}

.contactInputText {
    position: absolute;
    margin: 0;
    font-size: 16px;
    color: dimgray;
}

.contactSubmit {
    width: 100px;
    height: 40px;
    padding: 4px;
    margin: 16px auto;
    font-size: 20px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transform: translateY(-236px);
    transition: transform 0.5s ease-in-out;
}

.contactSubmitMoving {
    transform: translateY(0);
    transition: transform 0.5s ease-in-out;
}

.contactSubmit:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.contactSubmit:hover {
    background-color: #0056b3;
}

@media (max-width: 1024px) {
    .contact {
        width: 84%;
        padding: calc(84% / 30);
    }
}

.sendProcess {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.sendProcessContainer {
    max-width: 640px;
    width: 80%;
    max-height: 300px;
    height: 80%;
    background-color: white;
    padding: 16px;
    margin: calc(50vh - 150px) auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
}

.sendProcessText {
    font-size: 24px;
    text-align: center;
}

.sendProcessButton {
    display: block;
    width: 100px;
    height: 40px;
    margin: 0 auto;
    font-size: 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.sendProcessButton:hover {
    background-color: #3D8B3D;
    color: white;
    border: none;
}