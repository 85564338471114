body {
  margin: 0;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* クラフト明朝フォント */
@font-face {
  font-family: 'CraftMincho';
  src: url('../public/font/craftmincho.otf') format('opentype');
}

* {
  font-family: "alba", "Meiryo", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "メイリオ", "ＭＳ Ｐゴシック", "MS PGothic", "sans-serif";
  /* font-family: CraftMincho;*/
}