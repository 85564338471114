.accessMap {
    position: relative;
    margin: 0 auto;
    max-width: 936px;
    width: 100%;
    aspect-ratio: 2 / 1;
    border: 0;
    display: flex;
    justify-content: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

