/* LessonのCSS */
.lessonDetail {
    position: relative;
    max-width: 936px;
    padding: 32px;
    margin: 0 auto 64px auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.8);
}

.lessonDetailTitle {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    text-shadow: -2px 0 white, 0 2px white, 2px 0 white, 0 -2px white;
    z-index: 1;
}

.lessonDetailTitleBackground {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 280px;
    height: 64px;
    margin-left: -140px; /* widthの半分 */
    margin-top: -32px; /* heightの半分 */
    z-index: -1;
}

.lessonDetailTitleLeft {
    width: 48px;
    height: 48px;
}

.lessonText {
    font-size: 20px;
}

.lessonDetail table {
    width: 100%;
    border-collapse: collapse;
}

.lessonDetail th,
.lessonDetail td {
    padding: 10px;
    border: 1px solid #ddd;
}

.lessonDetail th {
    background-color: rgba(245, 245, 245, 0.5); /* #f5f5f5を半透明に設定 */
    text-align: center;
    font-size: 20px;
    width: 20%;
    min-width: 48px;
}

.lessonDetail td {
    text-align: center;
    font-size: 20px;
}

@media (max-width: 1024px) {
    .lessonDetail {
        width: 84%;
        padding: calc(84% / 30);
    }
    .lessonText,
    .lessonDetail th,
    .lessonDetail td {
        font-size: 16px;
    }
}

.lessonDetailButton {
    width: 240px;
    height: 40px;
    background-color: darkgray;
    border: none;
    color: white;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    margin: 32px auto 0 auto;
    cursor: pointer;
    border-radius: 10px;
    transition: all 0.3s ease;
}

.lessonDetailButton:hover {
    background-color: lightgray;
    transform: translateY(1px);
}

.lessonPrice {
    max-width: 936px;
    margin: 0 auto 64px auto;
    padding: 32px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.8);
}

.lessonPriceTitle {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    text-shadow: -2px 0 white, 0 2px white, 2px 0 white, 0 -2px white;
    z-index: 1;
}

.lessonPriceTitleBackground {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 160px;
    height: 64px;
    margin-left: -80px; /* widthの半分 */
    margin-top: -32px; /* heightの半分 */
    z-index: -1;
}

.lessonPriceTitleLeft {
    width: 48px;
    height: 48px;
}

.lessonPriceDetail {
    font-size: 20px;
}

.lessonPrice table {
    width: 80%;
    border-collapse: collapse;
    margin: 0 auto;
}

.lessonPrice th,
.lessonPrice td {
    padding: 10px;
    border: 1px solid #ddd;
}

.lessonPrice th {
    width: 40%;
    background-color: rgba(245, 245, 245, 0.5); /* #f5f5f5を半透明に設定 */
    text-align: center;
    font-size: 20px;
    min-width: 48px;
    white-space: normal;
}

.lessonPrice td {
    text-align: center;
    font-size: 20px;
    white-space: normal;
}

@media (max-width: 600px) {
    .lessonPrice table {
        width: 100%;
    }
    .lessonPrice th {
        width: 64px;
    }
}

.lessonPriceButton {
    width: 240px;
    height: 40px;
    background-color: darkgray;
    border: none;
    color: white;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    margin: 32px auto 0 auto;
    cursor: pointer;
    border-radius: 10px;
    transition: all 0.3s ease;
}

.lessonPriceButton:hover {
    background-color: lightgray;
    transform: translateY(1px);
}

@media (max-width: 1024px) {
    .lessonPrice {
        width: 84%;
        padding: calc(84% / 30);
    }
    .lessonPriceDetail,
    .lessonPrice th,
    .lessonPrice td {
        font-size: 16px;
    }
}