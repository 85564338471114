/* TeacherのCSS */

.teacher {
    position: relative;
    max-width: 936px;
    margin: 0 auto 64px auto;
    padding: 32px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.8);
}

.teacherTitle {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    text-shadow: -2px 0 white, 0 2px white, 2px 0 white, 0 -2px white;
    z-index: 1;
}

.teacherTitleBackground {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 220px;
    height: 64px;
    margin-left: -110px;
    /* widthの半分 */
    margin-top: -32px;
    /* heightの半分 */
    z-index: -1;
}

.teacherTitleLeft {
    width: 40px;
    height: 50px;
    margin-right: 8px;
}

.greeting {
    display: flex;
    align-items: center;
}

.greetingImageLeft {
    position: relative;
    display: block;
    width: 342px;
    height: 256px;
    border-radius: 10px;
    margin: 32px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
}

.greetingImageUnder {
    position: relative;
    display: block;
    max-width: 342px;
    width: 100%;
    aspect-ratio: 4 / 3;
    border-radius: 10px;
    margin: 32px;
    display: none;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
}

.greetingText {
    position: relative;
    display: block;
    font-size: 24px;
    line-height: 32px;
    margin: 32px;
}

.teacherInstagramText {
    font-size: 24px;
    text-align: center;
}

.lightwidget-widget {
    width: 100%;
    border: 0;
    overflow: hidden;
}

@media (max-width: 1024px) {
    .teacher {
        width: 84%;
        padding: calc(84% / 30);
    }
    .greeting {
        display: block;
    }
    .greetingImageLeft {
        display: none;
    }
    .greetingImageUnder {
        display: block;
        margin: 0 auto;
    }
    .greetingText {
        text-align: center;
        margin: 32px 0;
        font-size: 16px;
    }
    .teacherInstagramText {
        font-size: 16px;
    }
}