footer {
    position: relative;
    background-color: black;
    min-width: 320px;
    height: 190px;
    opacity: 0.7;
}

.fotterTabButton {
    color: white;
    max-width: 1024px;
    height: 50px;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-bottom: 2px solid gray;
    flex-wrap: wrap;
}

.fotterTabButton a {
    text-decoration: none;
    color: white;
    transition: all 0.3s ease;
    font-size: 16px;
    margin: 0 8px;
    width: 100px;
    text-align: center;
}

.fotterTabButton a:hover {
    color: coral;
    transform: translateY(2px);
}

.fotterInfomationTitle{
    color: white;
    font-size: 26px;
    text-align: center;
    margin: 4px auto;
}

.fotterContents {
    display: flex;
    max-width: 1024px;
    height: 66px;
    color: white;
    margin: 0 auto;
    border-bottom: 2px solid gray;
    justify-content: center;
}
/* フッター住所などの表示削除
.fotterAddress {
    width: 40%;
    height: 66px;
    color: white;
    text-align: center;
}

.fotterAddress p {
    margin: 6px;
} */

.footerInstagramIntroduction {
    display: flex;
    width: 20%;
    height: 64px;
    color: white;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-size: 24px;
}

.footerInstagramIntroduction:hover p {
    color: coral;
}

.footerInstagramIcon {
    position: relative;
    width: 50px;
    margin: 5px;
    opacity: 1;
    transition: all 0.3s ease;
}

.footerInstagramIntroduction:hover .footerInstagramIcon {
    opacity: 0.5;
}

.fotterCopyRight {
    color: white;
    text-align: center;
    margin: 4px auto;
    font-size: 12px;
}

