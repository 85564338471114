header {
    display: grid;
    place-items: center;
}

.logo {
    margin: 8px auto;
}

.borderText {
    position: absolute;
    top: 8px;
    left: calc(50% - 126px);
    font-size: 18px;
    margin: 12px 0;
    text-decoration: none;
    text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
}

.boardImage {
    position: relative;
    display: block;
    max-width: 300px;
    width: 100%;
    aspect-ratio: 5 / 1;
    margin: 0 auto;
}

.logoBackImage {
    position: absolute;
    top: 64px;
    left: calc(50% - 240px);
    max-width: 490px;
    width: 100%;
    aspect-ratio: 7 / 1;
}

.logoLeftImage {
    position: absolute;
    top: 56px;
    left: calc(50% - 260px);
    max-width: 80px;
    aspect-ratio: 1 / 1;
    transform: rotate(10deg);
}

.logoRightImage {
    position: absolute;
    top: 56px;
    right: calc(50% - 260px);
    max-width: 56px;
    width: 10%;
    aspect-ratio: 7 / 10;
}

.logoImage {
    position: relative;
    display: block;
    margin: 8px auto;
    max-width: 400px;
    width: 100%;
    aspect-ratio: 8 / 1;
}

@media (max-width: 512px) {
    .borderText {
        font-size: calc(18px - (16px - 100vw / 32));
        margin: calc(12px - (16px - 100vw / 32)) 0;
        left: calc(50% - 14 / 2 * (18px - (16px - 100vw / 32)));
        /* フォントサイズを引数に使用 */
    }
    .boardImage {
        width: 75%;
    }
    .logo {
        width: 80%;
    }
    .logoBackImage {
        width: 96%;
        left: 2%;
        top: 12.5vw;
    }
    .logoLeftImage {
        top: calc(12.5vw - 8px);
        left: 0%;
        width: 15.625%;
    }
    .logoRightImage {
        top: calc(12.5vw - 8px);
        right: 0%;
        width: 11%;
    }
    .logoImage {
        margin: calc(8px - (16px - 100vw / 32)) auto;
    }
}


/* スマホ用メニュー */

.headerMenu {
    z-index: 1;
}

.headerMenuBackground {
    position: fixed;
    top: 0;
    right: -160px;
    max-width: 512px;
    width: 160px;
    height: 100%;
    opacity: 0.8;
    background-color: black;
}

.headerMenuBackground.open {
    visibility: visible;
    right: 0;
    transition: right 0.8s ease-in-out;
}

.headerMenuOuter {
    position: fixed;
    display: none;
    top: 0;
    left: 0;
    width: calc(100% - 512px);
    height: 100%;
}

.headerMenuOuter.open {
    display: block;
}


/* スマホ用メニューボタン */

.headerMenuButton {
    position: fixed;
    top: 8px;
    right: 8px;
    border: none;
    cursor: pointer;
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 52px;
    height: 52px;
    border-radius: 4px;
    background-color: transparent;
    /* 背景色を無色に変更 */
}

.headerMenuButtonLine {
    width: 100%;
    height: 4px;
    opacity: 1;
    background-color: black;
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    /* 修正: opacityのアニメーションを追加 */
}


/* メニューボタンの開閉時のアニメーション */

.headerMenuButton.open .headerMenuButtonLine {
    background-color: white;
}

.headerMenuButton.open .headerMenuButtonLine:first-child {
    transform: translateY(12px) rotate(45deg);
    /* 修正: translateYの値を調整 */
}

.headerMenuButton.open .headerMenuButtonLine:nth-child(2) {
    opacity: 0;
}

.headerMenuButton.open .headerMenuButtonLine:last-child {
    transform: translateY(-12px) rotate(-45deg);
    /* 修正: translateYの値を調整 */
}

@media (max-width: 512px) {
    .headerMenu {
        width: 10%;
    }
}

.headerMenuList {
    width: 128px;
    margin: 64px 16px;
    padding: 0;
    transition: height 0.3s ease-in-out;
}

.headerMenuList.open {
    height: 100%;
    transition: height 0.3s ease-in-out;
}

.headerMenuListButton {
    padding: 4px;
    list-style-type: none;
    border-bottom: 3px double white;
}

.headerMenuListButton a {
    text-decoration: none;
    color: white;
    font-size: 16px;
}

.headerMenuListButton a:hover {
    color: coral;
}


/* タブボタンのコンポーネント */

.headerTab {
    padding-left: 0;
    margin-top: 0;
    background-color: #F472B6;
    padding: 0 20px 10px 20px;
    border-radius: 30px;
    box-shadow: 0 0 20px gray;
}

.headerTabButton {
    position: relative;
    list-style: none;
    display: inline-block;
    width: 150px;
    height: 40px;
    text-align: center;
    justify-content: center;
}

.headerTabButton a {
    display: block;
    color: black;
    font-size: 20px;
    margin: 10px;
    transition: all 0.3s ease;
    text-decoration: underline;
    text-underline-offset: 5px;
    text-decoration-color: lightgray;
    text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
}

.headerTabButton a:hover {
    transform: translateY(3px);
}

.headerTabBackground {
    width: 100%;
    height: 100%;
    background-color: black;
}