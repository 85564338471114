/* InstagramComponentのCSS */
.instagramComponent {
    position: relative;
    max-width: 936px;
    min-width: 256px;
    width: 100%;
    margin: 50px auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    padding: 32px;
    background-color: rgba(255, 255, 255, 0.8);
}

.instagramTitle {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    text-shadow: -2px 0 white, 0 2px white, 2px 0 white, 0 -2px white;
    z-index: 1;
}

.instagramTitleBackground {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 256px;
    height: 64px;
    margin-left: -128px; /* widthの半分 */
    margin-top: -32px; /* heightの半分 */
    z-index: -1;
}

.instagramTitleLeft {
    width: 42px;
    height: 42px;
    margin-right: 8px;
}

@media (max-width: 1024px) {
    .instagramComponent {
        width: 84%;
        padding: calc(84% / 30);
    }
}