/* NewsComponentのCSS */
.newsComponent {
    position: relative;
    max-width: 936px;
    min-width: 256px;
    width: 100%;
    margin: 50px auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    padding: 32px;
    background-color: rgba(255, 255, 255, 0.8);
}

@media (max-width: 1024px) {
    .newsComponent {
        width: 84%;
        padding: calc(84% / 30);
    }
}

.newsComponentTitle {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    text-shadow: -2px 0 white, 0 2px white, 2px 0 white, 0 -2px white;
    z-index: 1;
}

.newsComponentTitleBackground {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 220px;
    height: 64px;
    margin-left: -110px; /* widthの半分 */
    margin-top: -32px; /* heightの半分 */
    z-index: -1;
}

.newsComponentTitleLeft {
    width: 48px;
    height: 48px;
}

.allNewsList {
    width: 140px;
    height: 40px;
    background-color: darkgray;
    border: none;
    color: white;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    margin: 32px auto 0 auto;
    cursor: pointer;
    border-radius: 10px;
    transition: all 0.3s ease;
}

.allNewsList:hover {
    background-color: lightgray;
    transform: translateY(1px);
}