/* NewsDetailのCSS */
.newsDetail {
    position: relative;
    max-width: 768px;
    margin: 0 auto 64px auto;
    padding: 32px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.8);
}

.clearfix::after {
    content: "";
    display: table;
    clear: both;
}

.newsDetailTitle {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    text-shadow: -2px 0 white, 0 2px white, 2px 0 white, 0 -2px white;
    margin: 32px auto;
    z-index: 1;
}

.newsDetailTitleBackground {
    position: absolute;
    left: 50%;
    width: 100%;
    height: calc(100% + 16px);
    margin-left: -50%; /* widthの半分 */
    z-index: -1;
}

.newsDetailTitleLeft {
    width: 48px;
    height: 48px;
}

.newsDetailContainer {
    width: 90%;
    margin: 64px auto;
}

.newsDetailContent {
    position: relative;
    display: block;
    font-size: 24px;
    margin: 16px 0;
    line-height: 48px;
}

.newsDetailImage {
    position: relative;
    display: block;
    overflow: hidden;
    width: 100%;
}

.newsDetailImage.height-first {
    max-width: 256px;
    float: right;
    margin-left: 16px;
    /* 画像とテキストの間隔 */
}

.newsDetailImage.width-first {
    width: 100%;
    /* 画像の横幅を設定 */
    margin: 0 auto;
    /* 上下の余白を0にし、左右の余白を自動的に設定 */
}

.multiple-images {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* 画像が中央に来るように */
}

.newsDetailImage.multiple-images {
    padding: 4px;
    flex: none; /* 2列で表示、paddingの分を引く */
    max-width: calc(50% - 8px); /* 最大幅も指定しておく */
}

.newsDetailDate {
    position: relative;
    display: block;
    text-align: right;
    font-size: 16px;
    margin-top: 32px;
}

@media (max-width: 1024px) {
    .newsDetail {
        width: 84%;
        padding: calc(84% / 30);
    }
}

@media (max-width: 768px) {
    .newsDetailImage.height-first {
        float: none;
        margin: 0 auto;
    }
}

@media (max-width: 512px) {
    .newsDetailTitleLeft {
        width: calc(100vw / 32 * 3);
        height: calc(100vw / 32 * 3);
    }
    
    .newsDetailTitle {
        font-size: calc(100vw / 16);
    }

    .newsDetailData {
        font-size: calc(100vw / 32);
    }

    .newsDetailContent {
        font-size: calc(100vw / 21);
        line-height: calc(100vw / 12);
    }
    .newsDetailImage.multiple-images {
        flex: none; /* 2列で表示、paddingの分を引く */
        max-width: 100%;
    }
}

