/* FadeInのCSS */
.fade-in {
    opacity: 0;
    transform: translateY(64px);
    transition: opacity 1.5s ease-out, transform 1.5s ease-out;
}

.fade-in.visible {
    opacity: 1;
    transform: translateY(0);
}